<!--
Table of student data over a year. Used in star chart, payment chart, and kiosk chart
-->

<template>
  <div class="row">
        <div v-for="student in students" :key="student.id" class="col-sm-2">
          <table class="table">
            <td>{{ student.name }}</td>
            <td class="text-right">{{ student.minutes }}</td>
          </table>
        </div>
  </div>
</template>

<script>
import { ApiFactory } from "../api/ApiFactory";
const StudentAPI = ApiFactory.get("students");
var _ = require('lodash');


export default {
  name: 'MinutesTable',

  data(){
      return {
          student_ids: [],
          minutes: {},
          students: {}
      }
  },

  methods: {

      async update(){
        await StudentAPI.getList().then(({data}) =>{
          this.student_ids = data;
          StudentAPI.getSimpleFor(this.student_ids).then(({data}) =>{
            var raw_students = data;

            var nonzero_students = {}
            StudentAPI.getMinutesFor(this.student_ids).then(({data}) =>{
              for (const student_id in data) {
                var students_idx = raw_students.findIndex(x => x.id == student_id)
                nonzero_students[student_id] = raw_students[students_idx]
                nonzero_students[student_id].minutes = data[student_id];
              }

              this.students = _.orderBy(nonzero_students, ['last_name', 'first_name'],  ['asc', 'asc']); 
            });
          });
        });
      }
  },

  created: async function(){
      await this.update()

  },
}
</script>
