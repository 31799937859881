<template>
  <div class="container-fluid my-2">
      <h4 class="text-center py-3">
          Practicing Minutes for {{ date | moment("MMMM YYYY") }}
      </h4>
    <MinutesTable ref="mins">
    </MinutesTable>
  </div>
</template>

<script>
import MinutesTable from '../components/MinutesTable'

export default {
  
  components: {
    MinutesTable
  },

  data: function(){
    return {
      date: new Date()
    }
  }

}
</script>
